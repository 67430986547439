export function returnFloat (value) {
  const num = Math.round(parseFloat(value) * 100) / 100
  const s = num.toString().split('.')
  if (s.length === 1) {
    value = num.toString() + '.00'
    return value
  }
  if (s.length > 1) {
    if (s[1].length < 2) {
      value = num.toString() + '0'
    }
    return value
  }
}

<template>
  <div class="deals">
    <!-- 折扣商品左边 -->
    <div class="dealsLeft">
      <!-- 选择栏 -->
      <div class="deals-banner">
        <el-row>
          <!-- 价格 -->
          <el-col :span="6">
            <p>PRICE</p>
            <div class="priceInput">
              <span class="span1"> $ </span>
              <el-input-number v-model="priceNum1"
                               controls-position="right"
                               @change="handleChange"
                               :min="0"></el-input-number>
              <span class="span2"> -- $ </span>
              <el-input-number v-model="priceNum2"
                               controls-position="right"
                               @change="handleChange"
                               :min="0"></el-input-number>
              <el-button class="go"
                         @click="dealSeach()">go</el-button>
            </div>
          </el-col>
          <!-- 折扣 -->
          <el-col :span="6">
            <p>DISCOUNT</p>
            <el-select v-model="disValue">
              <el-option v-for="item in disInput"
                         :key="item.value1"
                         :label="item.label"
                         :value="item.value1">
              </el-option>
            </el-select>
          </el-col>
          <!-- 类别 -->
          <el-col :span="6">
            <p>CATEGORY</p>
            <el-select v-model="catValue">
              <el-option v-for="item in catInput"
                         :key="item.id"
                         :label="item.category"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- 排序 -->
          <el-col :span="6">
            <p>SORTING</p>
            <el-select v-model="sorValue">
              <el-option v-for="item in sorInput"
                         :key="item.value3"
                         :label="item.label"
                         :value="item.value3">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <!-- 商品栏 -->
      <div class="show-banner">
        <!-- Top Deals商品 -->
        <div class="topBox">
          <div class="title">Trending Deals</div>
        </div>
        <!-- 商品盒子 -->
        <el-row class="showboxs row-bg"
                type="flex"
                justify="space-between">
          <el-col :span="6"
                  class="goodbox"
                  v-for="(hotItem, index) in hotDeals"
                  :key="index"
                  @click.native="goodDeatail(hotItem)">
            <!-- 裁剪区域 -->
            <div class="yuan">
              <div class="yuanLeft"></div>
              <div class="xian"></div>
              <div class="yuanRight"></div>
            </div>
            <!-- 商品图片 -->
            <div class="goodImg">
              <img :src="hotItem.product_image_url" />
            </div>
            <!-- 商品标题 -->
            <div class="goodName">{{hotItem.product_title}}</div>
            <!-- 物流服务 -->
            <div class="goodLogistics">
              <span :class="hotItem.site_class"></span>
              <span style="color: #999999"> Fulfilled by Amazon</span>
              <!-- <div class="logImg">
                <img src="../../assets/img/flag.png"
                     alt="" />
              </div>
              <div class="logText">Beauty & Personal Care</div> -->
            </div>
            <!-- 商品价格 -->
            <div class="goodPrice">
              <div class="leftPrice">
                <del class="oldPrice">
                  <span v-if="hotItem.site === 'US'">$</span>
                  <span v-if="hotItem.site === 'JP'">￥</span>
                  <span v-if="hotItem.site === 'UK'">￡</span>
                  <span v-if="hotItem.site === 'CA' || hotItem.site === 'MX' || hotItem.site === 'DE' ||hotItem.site === 'FR' || hotItem.site === 'IT' || hotItem.site === 'ES'|| hotItem.site === 'NL' ">€</span>
                  {{hotItem.sale_price}}
                </del>
                <div class="bigDiscount">{{hotItem.discount_rate}}%</div>
              </div>
              <div class="newPrice">
                <span v-if="hotItem.site === 'US'">$</span>
                <span v-if="hotItem.site === 'JP'">￥</span>
                <span v-if="hotItem.site === 'UK'">￡</span>
                <span v-if="hotItem.site === 'CA' || hotItem.site === 'MX' || hotItem.site === 'DE' ||hotItem.site === 'FR' || hotItem.site === 'IT' || hotItem.site === 'ES'|| hotItem.site === 'NL' ">€</span>
                {{hotItem.deal_price}}
              </div>
            </div>
          </el-col>
          <div class="after"></div>
        </el-row>
      </div>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="pagSize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="pagTotal">
          </el-pagination>
    </div>
    <!-- 折扣商品右边 -->
    <!-- <div class="dealsRight">
      <div class="cheapClass">
        每周特价
        <h1>Weekly Hot Deals</h1>
        <div class="rightBox">
          <div class="hotBox"
               v-for="(rightItem, index) in cheapGoods"
               :key="index">
            特价商品图片
            <div class="hotImg">
              <img src="../../assets/img/hotGoods.jpg"
                   alt="" />
            </div>
            特价商品名称
            <div class="hotText">
              <p>Baby Walker</p>
              <div class="hotPrice">
                <div class="hotPrice-left">$0.00</div>
                <div class="hotPrice-right">
                  <img src="../../assets/img/review_s.jpg"
                       alt="" />
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// 引入接口
import {
  newDeaak, // 折扣商品
  deatilSearch // 折扣页面搜索
} from '../../api/buyerFront'
import { returnFloat } from '../../utils/num'
// 引入css样式
// import '../../assets/less/F_deals.css'
export default {
  data () {
    return {
      // 价格
      priceNum1: 0,
      priceNum2: 0,
      // 折扣
      disValue: '0-100',
      disInput: [
        {
          value1: '0-100',
          label: 'All Discounts'
        },
        {
          value1: '20-49',
          label: '20% off - 49% off'
        },
        {
          value1: '50-79',
          label: '50% off - 79% off'
        },
        {
          value1: '80-100',
          label: '80% off & more'
        }
      ],
      // 类别
      catValue: 1,
      catInput: [],
      // 排序
      sorValue: '4',
      sorInput: [
        {
          value3: '1',
          label: 'priceAsc'
        },
        {
          value3: '2',
          label: 'priceDesc'
        },
        {
          value3: '3',
          label: 'discountDesc'
        },
        {
          value3: '4',
          label: 'Newest'
        }
      ],
      // 热门交易商品
      hotDeals: [],
      // 分页
      currentPage: 1, // 当前页
      pagTotal: 0, // 总数
      pagSize: 10, // 当前页数量
      pageBool: false // 分页显示
    }
  },
  created () {
    this.$store.commit('test') // 调用vuex 的mutations方法
    this.catInput = this.$store.state.goodTypelist // 获取商品类型
    // 折扣
    newDeaak({
      page: this.currentPage, // 当前页
      size: this.pagSize, // 数量
      site: this.$store.state.countrySplit // 国家
    }).then(result => {
      if (result.data.code === 200) {
        this.hotDeals = result.data.data.rows // 获取热门交易商品数据
        this.pagTotal = result.data.data.total // 获取热门交易商品总数
        this.hotDeals.map(v => {
          v.product_image_url = v.product_image_url.split('"')[1]
          v.deal_price = returnFloat(v.deal_price)
          v.sale_price = returnFloat(v.sale_price)
        })
        if (this.hotDeals.length === 0) {
          this.pageBool = false
        } else {
          this.pageBool = true
        }
      }
      // // ('result =>', result)
    }).catch(err => {
      return err
    })
  },
  methods: {
    // 折扣跳转详情
    goodDeatail (item) {
      // ('item ==>', item)
      this.$router.push({ path: '/F_dealDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    },
    // 价格栏改变触发的事件
    handleChange (value) {
      // (value)
    },
    // 分页
    handleSizeChange (val) {
      this.pagSize = val
      // (`每页 ${val} 条`)
      // 折扣
      newDeaak({
        page: this.currentPage, // 当前页
        size: val, // 数量
        site: this.$store.state.countrySplit // 国家
      }).then(result => {
        if (result.data.code === 200) {
          this.hotDeals = result.data.data.rows // 获取热门交易商品数据
          this.pagTotal = result.data.data.total // 获取热门交易商品总数
          this.hotDeals.map(v => {
            v.product_image_url = v.product_image_url.split('"')[1]
            v.deal_price = returnFloat(v.deal_price)
            v.sale_price = returnFloat(v.sale_price)
          })
        }
        // // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    handleCurrentChange (val) {
      // 分页接口
      // 折扣
      newDeaak({
        page: val, // 当前页
        size: this.pagSize, // 数量
        site: this.$store.state.countrySplit // 国家
      }).then(result => {
        if (result.data.code === 200) {
          this.hotDeals = result.data.data.rows // 获取热门交易商品数据
          this.pagTotal = result.data.data.total // 获取热门交易商品总数
          this.hotDeals.map(v => {
            v.product_image_url = v.product_image_url.split('"')[1]
            v.deal_price = returnFloat(v.deal_price)
            v.sale_price = returnFloat(v.sale_price)
          })
        }
        // // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    // 搜索
    dealSeach () {
      // 折扣页面搜索
      deatilSearch({
        categoryId: this.catValue, // 商品id
        discountHigherLimit: this.disValue.split('-')[1], // 折扣率上限
        discountLowerLimit: this.disValue.split('-')[0], // 折扣率下限
        page: this.currentPage, // 当前页
        priceHigherLimit: this.priceNum2, // 搜索的价格上限
        priceLowerLimit: this.priceNum1, // 搜索的价格下限
        site: this.$store.state.countrySplit, // 国家
        size: this.pagSize, // 数量
        sorting: this.sorValue // 排序依据
      }).then(result => {
        if (result.data.code === 200) {
          this.hotDeals = result.data.data.rows // 获取热门交易商品数据
          this.pagTotal = result.data.data.total // 获取热门交易商品总数
          this.hotDeals.map(v => {
            v.product_image_url = v.product_image_url.split('"')[1]
            v.deal_price = returnFloat(v.deal_price)
            v.sale_price = returnFloat(v.sale_price)
          })
        }
      }).catch(err => {
        return err
      })
    }
  }
}
</script>
<style lang="less" scoped>
.deals {
  background-color: #f2f2f2;
  text-align: left;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  // deals左布局
  .dealsLeft {
    width: 100%;

    // 选择栏
    .deals-banner {
      width: 100%;
      padding: 0 10px;
      min-height: 100px;
      box-sizing: border-box;
      background: white;

       .el-input__suffix {
        display: flex;
        align-items: center;
      }

       .el-row {
        height: 100px;
        display: flex;
        align-items: center;
      }

       .el-col {
        border-right: 1px solid #EEEEEE;
        padding: 0 10px;
      }

       .el-select {
        width: 100%;
      }

       .priceInput {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

       .el-input-number {
        height: 30px;
        line-height: 30px;

        span {
          height: 15px;
        }
      }

       .el-input__inner {
        height: 30px;
        line-height: 30px;
        border-radius: 0;
      }

      .span1 {
        margin-right: 5px;
      }

      .span2 {
        margin: 0 5px;
        display: block;
        width: 40px;
      }

      .go {
        margin-left: 5px;
        padding: 6px 8px;
        font-size: 14px;
        color: white;
        background-color: #FF9933;
        border: 2px solid #FB8D20;
        border-radius: 0;
      }

      p {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }

    // 商品栏
    .show-banner {
      text-align: left;
      width: 100%;
      margin-top: 10px;

      .topBox {
        border-bottom: 1px solid #ccc;
      }

      .title {
        display: inline-block;
        font-size: 32px;
        font-weight: bold;
        padding-bottom: 5px;
        border-bottom: 3px solid #FF9933;
      }

      .showboxs {
        // float: left;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap; // flex-direction: column;

        .after {
          content: '';
          flex: auto;

          // &::after {
          //   display: block;
          //   content: "";
          //   width: 30%;
          //   height: 0px;
          // }
        }

        .goodbox {
          width: 100%;
          position: relative;
          width: 15%;
          background: white;
          height: 300px;
          padding: 10px;
          box-sizing: border-box;
          margin: 10px 2% 10px 0;
          cursor: pointer;

          &:nth-child(6n) {
            margin-right: 0;
          }

          .goodLike {
            pointer-events: auto;
            font-family: "iconfont" !important;
            font-size: 25px;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            display: inline;

            &::before {
              content: "\e68d";
            }

            &:hover {
              color: red;
            }
          }

          .yuan {
            position: absolute;
            left: 0;
            top: 165px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .yuanLeft {
              transform: translateX(-15px);
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: #f2f2f2;
            }

            .xian {
              border: 2px dashed #f2f2f2;
              width: 65%;
              height: 0;
            }

            .yuanRight {
              transform: translateX(15px);
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: #f2f2f2;
            }
          }

          .goodImg {
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;

            img {
              width: 150px;
              height: 120px;
            }
          }

          .goodName {
            padding: 0 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 10px 0;
          }

          .goodLogistics {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .logText {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #999;
            }
          }

          .goodPrice {
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;

            .leftPrice {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;

              .oldPrice {
                font-weight: 600;
              }

              .bigDiscount {
                padding: 5px 10px;
                background: #ff9933;
                color: white;
                border-radius: 5px;
                margin-left: 3px;
              }
            }

            .newPrice {
              font-size: 16px;
              color: #ff9933;
              font-weight: 600;
            }
          }

          .getPoint {
            margin: 10px auto;
            width: 80%;
            background-color: #f8ac59;
            color: white;
            border-radius: 20px;
            text-align: center;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

    }

    // 分页
     .el-pagination {
      display: flex;
      justify-content: center;
    }

     .el-pager li.active {
      color: #FB8D20;
    }

     .el-pager li {
      &:hover {
        color: #FB8D20;
      }
    }

     .el-pagination button {
      &:hover {
        color: #FB8D20;
      }
    }
  }

  // deals右布局
  .dealsRight {
    width: 21%;

    .cheapClass {
      background-color: white;
      padding-top: 15px;

      h1 {
        font-size: 20px;
        color: #ff9933;
        border-left: 5px solid #ff9933;
        padding: 0 15px;
        margin: 0 15px 25px 15px;
      }

      .scollRightBox {
        height: calc(100vh - 200px);
        overflow-y: auto;
      }

      .rightBox {
        background: white;

        // height: calc(100vh - 200px);
        // overflow-y: auto;
        // padding-bottom: 20px;
        .hotBox {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid #f2f2f2;

          .hotImg {
            height: 104px;
            width: 104px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .hotText {
            font-size: 16px;
            width: 60%;
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            p {
              width: 100%;
            }

            .hotPrice {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .hotPrice-left {
                color: #ce0000;
              }

              .hotPrice-right {
                span {
                  font-size: 14px;
                  color: #ce0000;
                  background-color: #f7c617;
                  padding: 4px 5px;
                  border-radius: 5px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }

  }
}
</style>
<style scoped>
.deals-banner >>> .el-input__inner {
  height: 30px;
}
.deals-banner >>> .el-input-number__increase {
  height: 15px;
}
</style>
